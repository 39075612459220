var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"wr_grey_1",attrs:{"flat":"","height":"100%"}},[(!_vm.user_plan || !_vm.user_plan.length)?_c('NoData',{attrs:{"heading":"app.research_journey_not_started","show_action":false}}):_c('FullCalendar',{ref:"fullCalendar",staticClass:"wr-calender full-height-wr",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('v-card',{attrs:{"width":"100%","flat":""}},[_c('v-menu',{attrs:{"transition":"slide-x-transition","left":!_vm.$vuetify.rtl,"right":_vm.$vuetify.rtl},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({class:[
								'fc-event-time',
								_vm.isMentor
									? null
									: arg.event.extendedProps.type == 'WithMentor'
									? 'mentor-event-wr'
									: 'self-event-wr' ],style:(_vm.isMentor
									? {
											background: _vm.getColor(
												arg.event.extendedProps.user_detail.id
											),
									  }
									: {}),attrs:{"tile":"","flat":"","width":"100%"}},'v-card',attrs,false),on),[_c('v-card-text',{class:[
									'fc-event-title',
									'py-1',
									'font-weight-bold',
									'text-truncate',
									_vm.isMentor ? 'black--text' : 'white--text' ]},[_vm._v(" "+_vm._s(arg.timeText)+" : "+_vm._s(arg.event.title)+" ")]),(_vm.isMentor)?_c('v-card-text',{staticClass:"fc-event-title font-weight-bold text-truncate"},[_c('v-chip',{attrs:{"color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$t("app.with"))+"- "),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm._f("capitalize")(arg.event.extendedProps.user_detail.name)))])])],1):_vm._e()],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"450"}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"11"}},[_c('div',{staticClass:"title black--text"},[_vm._v(" "+_vm._s(arg.event.title)+" ")]),(_vm.isMentor)?_c('div',{staticClass:"body-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t("app.with"))+"- "+_vm._s(arg.event.extendedProps.user_detail.name)+" ")]):_vm._e(),_c('div',{staticClass:"body-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t("app.from"))+"- "+_vm._s(_vm.moment(arg.event.start).format("DD/MM/YYYY, h:mm A"))+" ")]),_c('div',{staticClass:"body-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t("app.to"))+"- "+_vm._s(_vm.moment(arg.event.end).format("DD/MM/YYYY, h:mm A"))+" ")])]),_c('v-col',{staticClass:"text-end cursor-wr"},[_c('v-icon',[_vm._v("$vuetify.icons.values.cancel_basic")])],1)],1)],1),(!_vm.isMentor)?_c('v-card-actions',{staticClass:"mt-7"},[_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.editEvent(arg.event)}}},[_c('v-icon',[_vm._v("$vuetify.icons.values.edit")])],1),(arg.event.extendedProps.status != 'Auto')?_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.confirmDel(arg.event)}}},[_c('v-icon',[_vm._v("$vuetify.icons.values.delete")])],1):_vm._e(),_c('v-spacer'),(!_vm.isMentor)?_c('v-chip',{class:arg.event.extendedProps.type == 'WithMentor'
										? 'mentor-event-wr'
										: 'self-event-wr',attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$t( "app." + (arg.event.extendedProps.type.charAt(0).toUpperCase() + arg.event.extendedProps.type.slice(1)) ))+" ")]):_vm._e()],1):_vm._e()],1)],1)],1)]}}])}),_c('PopulateEventDialog',{attrs:{"dialog":_vm.event_dialog,"event":_vm.newEvent},on:{"close":function($event){_vm.event_dialog = false},"add-event":_vm.addEvent},model:{value:(_vm.event_dialog),callback:function ($$v) {_vm.event_dialog=$$v},expression:"event_dialog"}}),_c('TheConfirmation',{attrs:{"dialog":_vm.delete_dialog},on:{"cancel":function($event){_vm.delete_dialog = false},"confirm":function($event){return _vm.deleteEvent()}},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
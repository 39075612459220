var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.dialog,"width":"550"}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.event.id ? 'app.edit_event' : 'app.create_event',
		},"action":{
			label: 'app.save',
			event: 'validate',
		}},on:{"close":function($event){return _vm.$emit('close')},"validate":function($event){return _vm.validate()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-form',{ref:"form"},[_c('v-select',{attrs:{"outlined":"","color":"greenAccent2","background-color":"white","rules":[_vm.rules.required],"items":_vm.eventType,"label":_vm.$t('app.type_of_event')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")]}},{key:"selection",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")]}}]),model:{value:(_vm.event.type),callback:function ($$v) {_vm.$set(_vm.event, "type", $$v)},expression:"event.type"}}),_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.rules.required],"label":_vm.$t('app.title'),"readonly":_vm.event.id != undefined,"color":"greenAccent2","background-color":"white"},model:{value:(_vm.event.title),callback:function ($$v) {_vm.$set(_vm.event, "title", $$v)},expression:"event.title"}}),_c('v-datetime-picker',{attrs:{"label":_vm.$t('app.start'),"rules":[_vm.rules.required],"text-field-props":_vm.textFieldProps,"date-picker-props":_vm.dateProps,"time-picker-props":_vm.timeProps,"dateFormat":"dd/MM/yyyy","timeFormat":"hh:mm a","dialogWidth":450},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$vuetify.icons.values.calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$vuetify.icons.values.clock")])]},proxy:true},{key:"actions",fn:function(ref){
		var parent = ref.parent;
return [_c('v-card-actions',[_c('v-spacer'),_c('AppButton',{attrs:{"label":"app.okay","color":"info"},on:{"click":parent.okHandler}})],1)]}}]),model:{value:(_vm.event.plan_start_date),callback:function ($$v) {_vm.$set(_vm.event, "plan_start_date", $$v)},expression:"event.plan_start_date"}}),_c('v-datetime-picker',{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('app.end'),"text-field-props":_vm.textFieldProps,"date-picker-props":_vm.dateProps,"time-picker-props":_vm.timeProps,"dateFormat":"dd/MM/yyyy","timeFormat":"hh:mm a","dialogWidth":450},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$vuetify.icons.values.calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"info"}},[_vm._v("$vuetify.icons.values.clock")])]},proxy:true},{key:"actions",fn:function(ref){
		var parent = ref.parent;
return [_c('v-card-actions',[_c('v-spacer'),_c('AppButton',{attrs:{"label":"app.okay","color":"info"},on:{"click":parent.okHandler}})],1)]}}]),model:{value:(_vm.event.plan_end_date),callback:function ($$v) {_vm.$set(_vm.event, "plan_end_date", $$v)},expression:"event.plan_end_date"}})],1)]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
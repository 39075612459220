<template>
	<v-card flat height="100%" class="wr_grey_1">
		<NoData
			v-if="!user_plan || !user_plan.length"
			heading="app.research_journey_not_started"
			:show_action="false"
		></NoData>

		<FullCalendar
			v-else
			class="wr-calender full-height-wr"
			ref="fullCalendar"
			:options="calendarOptions"
		>
			<template v-slot:eventContent="arg">
				<v-card width="100%" flat>
					<v-menu
						transition="slide-x-transition"
						:left="!$vuetify.rtl"
						:right="$vuetify.rtl"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-card
								tile
								flat
								v-bind="attrs"
								v-on="on"
								width="100%"
								:class="[
									'fc-event-time',
									isMentor
										? null
										: arg.event.extendedProps.type == 'WithMentor'
										? 'mentor-event-wr'
										: 'self-event-wr',
								]"
								:style="
									isMentor
										? {
												background: getColor(
													arg.event.extendedProps.user_detail.id,
												),
										  }
										: {}
								"
							>
								<v-card-text
									:class="[
										'fc-event-title',
										'py-1',
										'font-weight-bold',
										'text-truncate',
										isMentor ? 'black--text' : 'white--text',
									]"
								>
									{{ arg.timeText }} : {{ arg.event.title }}
								</v-card-text>
								<v-card-text
									v-if="isMentor"
									class="fc-event-title font-weight-bold text-truncate"
								>
									<v-chip color="white" small>
										{{ $t("app.with") }}-
										<span class="mx-1">{{
											arg.event.extendedProps.user_detail.name | capitalize
										}}</span>
									</v-chip>
								</v-card-text>
							</v-card>
						</template>

						<v-card width="450">
							<v-card-text>
								<v-row no-gutters>
									<v-col sm="11">
										<div class="title black--text">
											{{ arg.event.title }}
										</div>
										<div v-if="isMentor" class="body-2 mt-3">
											{{ $t("app.with") }}-
											{{ arg.event.extendedProps.user_detail.name }}
										</div>
										<div class="body-2 mt-3">
											{{ $t("app.from") }}-
											{{ moment(arg.event.start).format("DD/MM/YYYY, h:mm A") }}
										</div>
										<div class="body-2 mt-3">
											{{ $t("app.to") }}-
											{{ moment(arg.event.end).format("DD/MM/YYYY, h:mm A") }}
										</div>
									</v-col>
									<v-col class="text-end cursor-wr">
										<v-icon>$vuetify.icons.values.cancel_basic</v-icon>
									</v-col>
								</v-row>
							</v-card-text>

							<v-card-actions class="mt-7" v-if="!isMentor">
								<v-btn icon text @click="editEvent(arg.event)">
									<v-icon>$vuetify.icons.values.edit</v-icon>
								</v-btn>
								<v-btn
									v-if="arg.event.extendedProps.status != 'Auto'"
									icon
									text
									@click="confirmDel(arg.event)"
								>
									<v-icon>$vuetify.icons.values.delete</v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<v-chip
									v-if="!isMentor"
									:class="
										arg.event.extendedProps.type == 'WithMentor'
											? 'mentor-event-wr'
											: 'self-event-wr'
									"
									dark
								>
									{{
										$t(
											"app." +
												(arg.event.extendedProps.type.charAt(0).toUpperCase() +
													arg.event.extendedProps.type.slice(1)),
										)
									}}
								</v-chip>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-card>
			</template>
		</FullCalendar>

		<!-- Create/update Event -->
		<PopulateEventDialog
			v-model="event_dialog"
			:dialog="event_dialog"
			:event="newEvent"
			@close="event_dialog = false"
			@add-event="addEvent"
		></PopulateEventDialog>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="deleteEvent()"
		></TheConfirmation>
	</v-card>
</template>
<script>
// Calendar dependencies
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import heLocale from "@fullcalendar/core/locales/he";
import arLocale from "@fullcalendar/core/locales/ar";
import enLocale from "@fullcalendar/core/locales/en-au";
// Others
import moment from "moment";
import rules from "@/utils/validation";
import { convertToNum, generateRandomColor } from "@/utils/helpers";
import { mapGetters, mapState } from "vuex";
import PopulateEventDialog from "@/components/research/modules/planAndManage/stages/planResearch/steps/PopulateEventDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "CalenderPlan",

	mixins: [notificationWriteMixin],

	props: {
		completePlan: {
			default: true,
		},
	},

	components: {
		FullCalendar,
		PopulateEventDialog,
		TheConfirmation,
		NoData,
	},

	data: function() {
		return {
			rules,
			moment,
			heLocale,
			arLocale,
			enLocale,
			colors: {},
			event: {},
			event_dialog: false,
			newEvent: {
				title: "",
				plan_start_date: "",
				plan_end_date: "",
				type: "",
			},
			delete_dialog: false,
			selectedEvent: null,
			dialog: false,
			// calendar options
			calendarOptions: {
				aspectRatio: 1.9,
				locales: [enLocale, heLocale, arLocale],
				plugins: [
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					rrulePlugin, // needed for dateClick
				],
				headerToolbar: {
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay",
				},
				initialView: "dayGridMonth",
				editable: false,
				selectable: true,
				selectMirror: true,
				dayMaxEvents: true,
				weekends: true,
				select: this.handleDateSelect,
				eventClick: this.handleEventClick,
				eventDrop: this.handleEventDrop,
				events: [],
				showNonCurrentDates: false,
			},
		};
	},

	watch: {
		allEvents(newVal, oldVal) {
			if (this.user_plan && this.user_plan.length) {
				this.setCalendarOptions();
			}
		},
	},

	computed: {
		...mapState({
			project_start_date: (state) =>
				state.planAndManage.start_date.project_start_date,
			current_module: (state) => state.research.current_module,
			user_plan: (state) => state.planAndManage.user_plan,
		}),

		...mapGetters({
			calendarPlan: "planAndManage/getCalendarPlan",
			isMentor: "user/isMentor",
			userLang: "user/getLanguage",
			userHasMentor: "user/hasMentor",
		}),

		allEvents() {
			return this.calendarPlan();
		},

		moduleEvents() {
			return this.calendarPlan(`M${this.current_module}`);
		},

		relavantEvents() {
			return this.completePlan ? this.allEvents : this.moduleEvents;
		},
	},

	created() {
		this.getUserPlan();
	},

	mounted() {
		this.init();
	},

	methods: {
		async init() {
			try {
				this.$loader.start();

				if (!this.user_plan || !this.user_plan.length) return;

				// Get project start date
				await this.getStartDate();
				// Set necessary calendar options
				this.setCalendarOptions();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		getColor(user_id) {
			// if color is not exist already then generate random.
			if (!this.colors[user_id]) {
				this.colors[user_id] = generateRandomColor();
			}
			return this.colors[user_id];
		},

		setCalendarOptions() {
			try {
				if (!this.user_plan && !this.user_plan.length) return;

				// Calendar should be editable if it's not of mentor
				this.calendarOptions.editable = !this.isMentor;
				// Calendar's events
				this.calendarOptions.events = this.relavantEvents;
				// Calendar's locales
				this.calendarOptions.locale = this[`${this.userLang}Locale`];
				// Date to set range
				let date = moment(this.relavantEvents[0].start)
					.startOf("month")
					.format("YYYY-MM-DD");
				// Calendar's valid range
				this.calendarOptions.validRange = {
					start: date,
				};
				// Calendar's goto date (calendar starting date)
				this.$nextTick(() => {
					if (this.$refs.fullCalendar) {
						let calendarAPI = this.$refs.fullCalendar.getApi();
						if (calendarAPI) {
							calendarAPI.gotoDate(date);
						}
					}
				});
			} catch (error) {
				throw error;
			}
		},

		async getStartDate() {
			await this.$store.dispatch("planAndManage/getStartDate");
		},

		editEvent(event) {
			try {
				this.$loader.start();

				this.newEvent.id = event.id;
				this.newEvent.title = event.title;
				this.newEvent.plan_start_date = event.start;
				this.newEvent.plan_end_date = event.end;
				this.newEvent.type = event.extendedProps.type;
				this.event_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async addEvent(cb) {
			try {
				this.checkIfEventExists();

				await this.$store.dispatch(
					this.newEvent.id != undefined
						? "planAndManage/updateUserPlan"
						: "planAndManage/createUserPlan",
					this.newEvent,
				);

				await this.$store.dispatch("planAndManage/getUserPlan");
				if (this.newEvent.type.toLowerCase() === "withmentor") {
					// Send notification to mentor that new meeting has been canceled
					await this.notifyToMentor({
						msg_title:
							this.newEvent.id == undefined
								? this.$t("app.new_invitation")
								: this.$t("app.updated_invitation"),
						msg_content:
							this.newEvent.id == undefined
								? this.$t("app.you_have_invited_msg", {
										event_name: this.newEvent.title,
										date: moment(this.newEvent.plan_start_date).format("LLL"),
								  })
								: this.$t("app.event_has_updated_msg", {
										event_name: this.newEvent.title,
										old_date: moment(this.newEvent.plan_start_date).format(
											"LLL",
										),
										new_date: moment(this.newEvent.plan_end_date).format("LLL"),
								  }),
					});
				}

				this.$announce.success(
					this.newEvent.id != undefined
						? "app.ntfy.succ.event_updated"
						: "app.ntfy.succ.event_added",
				);

				cb();

				this.event_dialog = false;
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		checkIfEventExists() {
			/**
			 * Find the event which date is equal to new event and timing lies
			 * between existing time.
			 */

			let result = this.calendarOptions.events.find((item) => {
				/**
				 * Let's say a meeting is already scheduled at 6PM - 7PM.
				 * following meeting time should not be allowed on same date-
				 * 6PM-7PM, 6:10PM-6:30PM, 5PM-8PM, 5PM-6:30PM etc.
				 */

				let new_start_time = moment(this.newEvent.plan_start_date).format(
					"hh:mm:ss a",
				);
				let new_end_time = moment(this.newEvent.plan_end_date).format(
					"hh:mm:ss a",
				);
				let existed_start_time = moment(item.start).format("hh:mm:ss a");
				let existed_end_time = moment(item.end).format("hh:mm:ss a");

				return (
					item.id != this.newEvent.id && // Do not match if same item is going to be compare
					moment(item.start).format("YYYY-MM-DD") ===
						moment(this.newEvent.plan_start_date).format("YYYY-MM-DD") &&
					((new_start_time >= existed_start_time &&
						new_start_time <= existed_end_time) ||
						(new_end_time >= existed_start_time &&
							new_end_time <= existed_end_time) ||
						(new_start_time < existed_start_time &&
							new_end_time > existed_end_time))
				);
			});

			if (result) {
				throw new Error("app.ntfy.err.can_not_book_meeting_on_same_time", {
					cause: "werCustom",
				});
			}
		},

		handleDateSelect(selectInfo) {
			try {
				if (this.isMentor || !this.completePlan) return false;

				delete this.newEvent.id;

				this.newEvent.title = "";
				this.newEvent.type = "";
				this.newEvent.plan_start_date = selectInfo.start;
				this.newEvent.plan_end_date = moment(selectInfo.start).add(
					1,
					"hours",
				)._d;
				this.event_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		handleEventClick(clickInfo) {
			try {
				this.event = clickInfo;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		confirmDel(event) {
			try {
				this.selectedEvent = event;
				this.delete_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteEvent() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/delUserPlan", {
					id: this.selectedEvent.id,
				});

				if (
					this.selectedEvent.extendedProps.type.toLowerCase() === "withmentor"
				) {
					// Send notification to mentor that meeting has been canceled
					await this.notifyToMentor({
						msg_title: this.$t("app.canceled_invitation"),
						msg_content: this.$t("app.event_has_canceled_msg", {
							event_name: this.selectedEvent.title,
							date: moment(this.selectedEvent.startStr).format("LLL"),
						}),
					});
				}

				this.delete_dialog = false;

				this.$announce.success("app.ntfy.succ.event_deleted");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async handleEventDrop(info) {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/updateUserPlan", {
					id: info.event.id,
					title: info.event.title,
					plan_start_date: info.event.startStr,
					plan_end_date: info.event.endStr,
					type: info.event.extendedProps.type,
				});

				if (info.event.extendedProps.type.toLowerCase() === "withmentor") {
					// Send notification to mentor that meeting has been updated
					await this.notifyToMentor({
						msg_title: this.$t("app.updated_invitation"),
						msg_content: this.$t("app.event_has_updated_msg", {
							event_name: info.event.title,
							old_date: moment(info.oldEvent.startStr).format("LLL"),
							new_date: moment(info.event.startStr).format("LLL"),
						}),
					});
				}

				this.$announce.success("app.ntfy.succ.event_updated");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async notifyToMentor(payload) {
			if (this.userHasMentor) {
				let response = await this.$store.dispatch("notification/send", {
					recipient: "mentor",
					msg_title: payload.msg_title,
					msg_content: payload.msg_content,
				});
				// Now, Write this message to firebase
				await this.writeNotification(response);
			}
		},
	},
};
</script>

<style scoped>
>>> .fc-direction-ltr >>> .fc-daygrid-event.fc-event-start,
>>> .fc-direction-rtl >>> .fc-daygrid-event.fc-event-end {
	margin: 0px !important;
}

>>> .fc-daygrid-event-harness {
	right: 0 !important;
	top: 0 !important;
}

.self-event-wr {
	background: var(--v-info-base) !important;
}

.mentor-event-wr {
	background: var(--v-error-base) !important;
}
</style>

<template>
	<v-dialog persistent :value="dialog" width="550">
		<AppDialog
			v-model="loading"
			:heading="{
				label: event.id ? 'app.edit_event' : 'app.create_event',
			}"
			:action="{
				label: 'app.save',
				event: 'validate',
			}"
			@close="$emit('close')"
			@validate="validate()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form">
					<v-select
						outlined
						color="greenAccent2"
						background-color="white"
						:rules="[rules.required]"
						:items="eventType"
						v-model="event.type"
						:label="$t('app.type_of_event')"
					>
						<template v-slot:item="{ item }">
							{{ $t(item.text) }}
						</template>
						<template v-slot:selection="{ item }">
							{{ $t(item.text) }}
						</template>
					</v-select>

					<v-text-field
						outlined
						v-model="event.title"
						:rules="[rules.required]"
						:label="$t('app.title')"
						:readonly="event.id != undefined"
						color="greenAccent2"
						background-color="white"
					></v-text-field>

					<v-datetime-picker
						:label="$t('app.start')"
						v-model="event.plan_start_date"
						:rules="[rules.required]"
						:text-field-props="textFieldProps"
						:date-picker-props="dateProps"
						:time-picker-props="timeProps"
						dateFormat="dd/MM/yyyy"
						timeFormat="hh:mm a"
						:dialogWidth="450"
					>
						<template v-slot:dateIcon>
							<v-icon color="info">$vuetify.icons.values.calendar</v-icon>
						</template>
						<template v-slot:timeIcon>
							<v-icon color="info">$vuetify.icons.values.clock</v-icon>
						</template>
						<template slot="actions" slot-scope="{ parent }">
							<v-card-actions>
								<v-spacer></v-spacer>
								<AppButton
									label="app.okay"
									color="info"
									@click="parent.okHandler"
								/>
							</v-card-actions>
						</template>
					</v-datetime-picker>

					<v-datetime-picker
						:rules="[rules.required]"
						:label="$t('app.end')"
						v-model="event.plan_end_date"
						:text-field-props="textFieldProps"
						:date-picker-props="dateProps"
						:time-picker-props="timeProps"
						dateFormat="dd/MM/yyyy"
						timeFormat="hh:mm a"
						:dialogWidth="450"
					>
						<template v-slot:dateIcon>
							<v-icon color="info">$vuetify.icons.values.calendar</v-icon>
						</template>
						<template v-slot:timeIcon>
							<v-icon color="info">$vuetify.icons.values.clock</v-icon>
						</template>
						<template slot="actions" slot-scope="{ parent }">
							<v-card-actions>
								<v-spacer></v-spacer>
								<AppButton
									label="app.okay"
									color="info"
									@click="parent.okHandler"
								></AppButton>
							</v-card-actions>
						</template>
					</v-datetime-picker>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";
import { datePickerLocaleMixin } from "@/mixins/datePickerLocaleMixin";

export default {
	name: "PopulateEventDialog",

	mixins: [datePickerLocaleMixin],

	props: {
		dialog: {
			required: true,
		},
		event: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			eventType: [
				{
					text: "app.Self",
					value: "Self",
				},
				{ text: "app.WithMentor", value: "WithMentor" },
			],
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		dateProps() {
			return {
				color: "info",
				flat: true,
				locale: this.lang,
			};
		},

		timeProps() {
			return {
				color: "info",
				format: "24hr",
				flat: true,
				locale: this.lang,
			};
		},

		textFieldProps() {
			return {
				outlined: true,
				appendIcon: this.$vuetify.icons.values.calendar,
			};
		},
	},

	methods: {
		validate() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				this.$emit("add-event", (reset = true) => {
					this.loading = false;
					if (reset) {
						this.$refs.form.reset();
					}
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},
	},
};
</script>
